import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  Grid,
  Paragraphs,
  SectionHead,
  List,
  ContentBox,
  HalvedContent,
  Dialog,
  NonStretchedImage
} from 'src/components';
import content from 'src/content/unsere-dienstleistungen/bav-beratung-und-betreuung/xbav-und-epension';
import subpageNavigations from 'src/data/subpage-navigations';

const XbAVUndePensionPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: 'right'
    }}
    subpageNavItems={subpageNavigations.beratungUndBetreuung}
    activeNavItem={1}
    useParentPathname
  >
    <section>
      <Grid>
        <HalvedContent swapped breakpoint="xl">
          <BackgroundImage fluid={data.womanAtWork.childImageSharp.fluid} />
          <ContentBox
            background="gray-blue"
            topline={content.intro.topline}
            headline={content.intro.headline}
            align="right"
          />
        </HalvedContent>
        <p>{content.intro.paragraph}</p>
      </Grid>
    </section>
    <section>
      <Grid centerItems>
        <div className="children-margin">
          <h3>{content.info.headline}</h3>
          <Paragraphs items={content.info.paragraphs} />
        </div>
        <NonStretchedImage fluid={data.illuVerwaltung.childImageSharp.fluid} />
      </Grid>
      <div className="container container--simple">
        <NonStretchedImage fluid={data.illuWorkflow.childImageSharp.fluid} />
      </div>
      <ContentBox
        background="light-blue"
        headline={content.vorteile.headline}
        single
      >
        <List items={content.vorteile.listItems} columns={2} />
      </ContentBox>
    </section>
    <section className="no-children-margin">
      <SectionHead headline={content.partner.headline} gap>
        <p>{content.partner.paragraph}</p>
      </SectionHead>
      <HalvedContent swapped>
        <BackgroundImage fluid={data.busyDudes.childImageSharp.fluid} />
        <ContentBox headline={content.xbav.headline} align="right" wider>
          <Paragraphs items={content.xbav.paragraphs} />
          <Dialog
            buttonVariant="gray-blue"
            buttonText={content.xbav.dialog.buttonText}
            title={content.xbav.dialog.title}
          >
            <ul>
              {content.xbav.dialog.listItems.map((listItem, index) => (
                <li key={index}>{listItem}</li>
              ))}
            </ul>
            <p>
              {content.xbav.dialog.bottomText}
              &nbsp;
              <strong>
                <a href={content.xbav.dialog.href}>
                  {content.xbav.dialog.href}
                </a>
              </strong>
            </p>
          </Dialog>
        </ContentBox>
      </HalvedContent>
      <HalvedContent>
        <BackgroundImage fluid={data.laughingDude.childImageSharp.fluid} />
        <ContentBox
          background="light-pink"
          headline={content.ePension.headline}
          align="left"
          wider
        >
          <Paragraphs items={content.ePension.paragraphs} />
          <Dialog
            buttonVariant="gray-blue"
            buttonText={content.ePension.dialog.buttonText}
            title={content.ePension.dialog.title}
          >
            <ul>
              {content.ePension.dialog.listItems.map((listItem, index) => (
                <li key={index}>{listItem}</li>
              ))}
            </ul>
            <p>
              {content.ePension.dialog.bottomText}
              &nbsp;
              <strong>
                <a href={content.ePension.dialog.href}>
                  {content.ePension.dialog.href}
                </a>
              </strong>
            </p>
          </Dialog>
        </ContentBox>
      </HalvedContent>
      <ContentBox single>
        <span className="text-2xl">
          {content.contactXbav.contactText[0]}
          <br></br>
          {content.contactXbav.contactText[1]}{' '}
          <a
            className="font-bold"
            href={`mailto:${content.contactXbav.emailLink}`}
          >
            {content.contactXbav.emailLink}
          </a>
        </span>
      </ContentBox>
    </section>
  </MainLayout>
);

export default XbAVUndePensionPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-with-glasses.jpg" }
    ) {
      ...image
    }
    womanAtWork: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "women-with-notebook-at-work.jpg" }
    ) {
      ...image
    }
    busyDudes: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "Busy-dudes-at-work.jpg" }
    ) {
      ...image
    }
    laughingDude: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-sitting-at-his-workspace-and-smile.jpg" }
    ) {
      ...image
    }
    illuVerwaltung: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "bav-vertraege-verwaltung.png" }
    ) {
      ...image
    }
    illuWorkflow: file(
      sourceInstanceName: { eq: "illustrations" }
      relativePath: { eq: "bav-vertrage-workflow.png" }
    ) {
      ...image
    }
  }
`;
