const content = {
  title: 'bAV-Portale - XEMPUS & ePension',
  headvisualHeadline: 'bAV-Portale - XEMPUS & ePension',
  intro: {
    topline: 'XEMPUS und ePension',
    headline: 'Ihre Betriebsrente jetzt online verwalten.',
    paragraph:
      'Hier eine neue Vorschrift, da eine neue Regelung: Der Verwaltungsaufwand der betrieblichen Altersversorgung (bAV) dürfte auch in den nächsten Jahren nicht weniger werden. Vielen Arbeitgebern ist die Verwaltung der bAV mit zu viel Aufwand verbunden. Machen Sie es sich einfach und sorgen mit den Portallösungen der NÜRNBERGER für schnelle, digitale und effiziente Verwaltungsabläufe.'
  },
  info: {
    headline: 'Alles online erledigt',
    paragraphs: [
      'In Ihrem bAV-Portal sind alle Vertragsinformationen für Sie jederzeit online zu sehen und zu bearbeiten. Zusätzlich können Sie weiteren Personen, z. B. Ihrem Vermittler oder Steuerberater, Lese- und Schreibrechte geben. So sind alle immer auf dem gleichen Stand.',
      'Keine zeitaufwendigen Rückfragen mehr per E-Mail, Telefon oder Schriftverkehr. Mit den NÜRNBERGER bAV-Portalen lassen sich Aufträge schnell veranlassen und erledigen.'
    ]
  },
  vorteile: {
    headline: 'bAV-Verwaltung digital - Ihre Vorteile',
    listItems: [
      'Kostenlos für Sie als Arbeitgeber',
      'Ermöglicht immer und sofort Zugriff auf alle Dokumente und Vertragsdetails',
      'Entlastet bei allen gängigen Verwaltungsaufgaben – so bleibt Ihnen und Ihren Mitarbeitern mehr Zeit, sich auf Kernaufgaben in Ihrem Unternehmen zu konzentrieren',
      'Vermeidet lange Kommunikationswege',
      'Bietet maximale Übersicht und datenschutzkonforme Prozesse – ob bei Auftragserteilung, Abfragen von Vorgängen oder Archivierung',
      'Unterstützt mit aktueller Technologie, rechtlich konform'
    ]
  },
  partner: {
    headline: 'Zwei starke Partner',
    paragraph:
      'Mit XEMPUS und ePension setzt die NÜRNBERGER auf zwei etablierte und kompetente Partner, die bereits große Erfahrung im Bereich der digitalen bAV-Verwaltung vorweisen können. So finden Sie die perfekte Lösung für Ihre bAV-Verwaltung.'
  },
  xbav: {
    headline: 'bAV-Verwaltug mit XEMPUS',
    paragraphs: [
      'Sie bieten Ihren Mitarbeitern mit einer betrieblichen Altersvorsorge (bAV) einen echten Mehrwert. Da ist es doch selbstverständlich, dass Sie immer einen schnellen und aktuellen Überblick über die bAV-Verträge haben möchten.',
      'Mit unserem XEMPUS-Verwaltungsportal verwalten Sie jetzt Ihre bAV-Verträge direkt online. Das Melden von beitragsfreien Zeiten oder anderen Vertragsänderungen erledigen Sie effizient per Mausklick. Dabei sind die Geschäftsvorfälle perfekt auf den Firmenkunden ausgerichtet.'
    ],
    dialog: {
      buttonText: 'Übersicht Geschäftsvorfälle',
      title: 'Diese Geschäftsvorfälle erledigen Sie bequem online:',
      listItems: [
        'Beitragsänderungen',
        'Beitragsfreistellungen',
        'Elternzeit melden',
        'Krankheit melden',
        'Stammdaten der versicherten Person ändern',
        'Wiederinkraftsetzung eines Vertrags',
        'Austritt melden'
      ],
      bottomText: 'Weitere Informationen finden Sie unter:',
      href: 'https://www.nuernberger-bav.net/'
    }
  },
  ePension: {
    headline: 'NÜRNBERGER bAV-Portal: ePension',
    paragraphs: [
      'Keine lästige Suche mehr nach dem richtigen Formular, keine Rückfragen vom Versicherer. Mit dem NÜRNBERGER bAV-Portal von ePension werden Änderungen schnell und effizient erledigt. Das gibt Ihnen und Ihren Mitarbeitern die Möglichkeit, sich auf die Kernaufgaben in Ihrem Unternehmen zu konzentrieren.',
      'Es gibt immer mehr gesetzliche Vorgaben und Regulierungen im arbeits-, steuer- und datenschutzrechtlichen Umfeld eines Unternehmens. Machen Sie sich das Leben leichter und profitieren Sie von den Vorteilen, unseres bAV-Verwaltungsportals von ePension.'
    ],
    dialog: {
      buttonText: 'Übersicht Geschäftsvorfälle',
      title: 'Diese Geschäftsvorfälle erledigen Sie bequem online:',
      listItems: [
        'Beitragsfreistellungen',
        'Elternzeit melden',
        'Krankheit melden',
        'Stammdaten der versicherten Person ändern',
        'Wiederinkraftsetzung eines Vertrags',
        'Austritt melden'
      ],
      bottomText: 'Weitere Informationen finden Sie unter:',
      href: 'https://www.nuernberger-epension.net'
    }
  },
  contactXbav: {
    contactText: [
      'Sie haben Interesse oder möchten weitere Informationen erhalten?',
      'Kontaktieren Sie uns gerne per E-Mail an'
    ],
    emailLink: 'bav-portal@nuernberger.de'
  }
};

export default content;
